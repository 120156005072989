.pet-age.MuiFormGroup-root {
  flex-direction: row;

  .pet-age-group-label {
    width: 100%;
  }

  .month-year-sep {
    display: inline-block;
    height: 2rem;
    font-size: 2rem;
    line-height: 2rem;
    padding: 1rem 0.5rem 0;
  }

  .MuiTextField-root {
    width: 4rem;
  }

  .age-in-years-and-months {
    display: inline-block;
    height: 1.5rem;
    line-height: 2rem;
    padding: 1rem 0 0 1rem;

    &.--error {
      color: #c00;
    }
  }
}
