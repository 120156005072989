.pet-ideal-weight {
  .MuiSlider-root {
    margin: 0 2.5rem;

    .MuiSlider-valueLabel {
      top: 3rem;
      background-color: unset;
      color: rgba(0, 0, 0, 0.87);
      font-weight: 400;

      &:before {
        display: none;
      }
    }
  }
}
