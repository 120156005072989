.jffd-feeding-calculator {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  header {
    width: 100%;
    padding: 1rem;

    h1.MuiTypography-root {
      font-size: 1.25rem;
    }

    h2.MuiTypography-root {
      font-size: 1.6rem;
    }
  }

  > .MuiPaper-root.calculator-form {
    padding: 1rem;

    > .MuiFormGroup-root {
      width: 100%;
      margin: 2rem 0;
      flex-direction: row;

      &:first-child {
        margin-top: 0;
      }

      > .MuiFormLabel-root {
        width: 100%;
      }
    }
  }

  > .MuiPaper-root {
    box-sizing: border-box;
    width: calc(50% - 2rem);
    min-width: 30rem;
    margin: 1rem;
  }

  .results {
    h6.MuiTypography-root {
      margin-top: 1rem;
    }
  }
}
